import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import Dropzone from 'react-dropzone';
import axios from 'axios';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../utils/normalize.css';
import '../utils/css/screen.css';

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        uploaderUrl
      }
    }
    captureHint: file(
      relativePath: { eq: "capture-hint.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 166) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const UploaderPage = ({ data }, location) => {
  const { title, uploaderUrl } = data.site.siteMetadata;
  console.log(title, uploaderUrl);

  let isMobileUser = false;
  let mobileOS = '';

  // Only check for mobile operating system
  // when not server-side rendering.
  // This is an important hack that allows us
  // to get around an error that appears on Gatsby Cloud:
  // "navigator" is not available during server side rendering.
  const isSSR = typeof window === 'undefined';
  if (!isSSR) {
    const getMobileOperatingSystem = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
      }

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    };

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      isMobileUser = true;
      mobileOS = getMobileOperatingSystem();
    }
  }

  const [uploadResults, setUploadResults] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [msgValue, setMsgValue] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [tag, setTag] = useState('');
  const [book, setBook] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('tag')) setTag(params.get('tag'));
    if (params.has('book')) setBook(params.get('book'));
  }, []);

  const onDrop = (acceptedFiles) => {

    // Push all the axios request promise into a single array
    const uploaders = acceptedFiles.map((file) => {
      uploadFile(file);
    });

    // Once all the files are uploaded
    axios.all(uploaders).then(() => {
    // ... perform after upload is successful operation
      console.log('Uploads completed');
    });
  };

  const handleMsgChange = (event) => {
    setMsgValue(event.target.value);
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    // Add any settings/effects coming from query string
    let uploadMessage = msgValue;
    if (tag && tag !== '') uploadMessage += (` tag ${tag}`);

    // Append book-specific settings
    if (book && book !== '') {
      if (book === 'naughty-cats') uploadMessage += (' FPS 10');
      // if (book === 'text-reactions') uploadMessage += (' FPS 10');

      // By default we add two effects to coloring books
      // so they juice up colors a bit and fix contrast.
      uploadMessage += (' contrast-stretch auto white');
    }

    formData.append('inputMessage', uploadMessage);

    setWaiting(true);
    axios.post(uploaderUrl, formData, {
      onUploadProgress: (ProgressEvent) => {
        const progress = `${Math.round(
          ProgressEvent.loaded / ProgressEvent.total * 100,
        )}%`;
        console.log(progress);
      },
    }).then((res) => {

      setWaiting(false);
      setUploadResults(res.data);
    }).catch((err) => {
      console.log(err);
      setWaiting(false);
      setErrorMsg(err.toString());
    });
  };

  return (
    <Layout title={title}>
      <SEO title="Uploader" keywords={['uploader', 'gif', 'high-res', 'animation']} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          { !isMobileUser && (
          <h1>
            Uploader
          </h1>
          )}
          { (!waiting && !uploadResults) && (
            <div>
              <div className="col-12">
                <textarea
                  name="upload-message"
                  id="upload-message"
                  placeholder="Effects & settings."
                  rows={1}
                  value={msgValue}
                  onChange={handleMsgChange}
                />
              </div>
              <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} accept="image/*">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="drop-area">
                      <input {...getInputProps()} />
                      <br />
                      { isMobileUser ? (
                        <div>
                          <p>
                            <strong><a>Tap here</a></strong>
                            <br />
                            then
                            {' '}
                            {(mobileOS === 'iOS')
                              ? (
                                <span>
                                  <br />
                                  Take Photo
                                </span>
                              )
                              : (
                                <span>
                                  <br />
                                  Open Camera
                                </span>
                              )
                            }
                          </p>
                          <div className="capture-hint">
                            <Img
                              fluid={data.captureHint.childImageSharp.fluid}
                              className="kg-image"
                            />
                          </div>
                          <p className="photo-hint">
                            Capture
                            {' '}
                            <strong>
                              all four
                            </strong>
                            {' '}
                            corner markers.
                          </p>
                        </div>
                      ) : (
                        <p>
                          <strong><a>Click here</a></strong>
                          {' '}
                          to select file
                          <br />
                          or drag directly into box.
                        </p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <br />
              { !isMobileUser && (
              <figcaption className="uploader-tip">
                JPG and PNG images only.
              </figcaption>
              )}

            </div>
          )}
          { uploadResults && (
            <section className="process-section">
              {(!uploadResults.errorMsg || uploadResults.errorMsg === '')
                ? (
                  <h3>Done! Yum.</h3>
                )
                : (
                  <h5>{uploadResults.errorMsg}</h5>
                )
              }
              { (uploadResults.resultsMessage && uploadResults.resultsMessage.length > 0) && (
                <figcaption>{uploadResults.resultsMessage}</figcaption>
              )}
              {(uploadResults.resultsMedia.length === 1)
                ? (
                  <React.Fragment>
                    <img src={uploadResults.resultsMedia[0]} style={{ maxWidth: 320 }} alt="results" />
                    <br />
                    <br />
                    { isMobileUser ? (
                      <>
                        { !uploadResults.errorMsg && (
                          <p>
                            To download:
                            <br />
                            Tap and hold GIF, then
                            {' '}
                            {(mobileOS === 'iOS')
                              ? (
                                <strong>Add to Photos.</strong>
                              ) : (
                                <strong>Download image.</strong>
                              )}
                          </p>
                        )
                      }
                      </>
                    ) : (
                      <a href={uploadResults.resultsMedia[0]} className="button print primary small" style={{ fontWeight: 600 }}>
                        Download
                      </a>
                    )}
                    <button onClick={() => { setUploadResults(null); }} type="button" className="button print primary fit large" style={{ fontWeight: 600 }}>
                      ↻ Reset
                    </button>
                  </React.Fragment>
                )
                : (
                  <div className="row">
                    { uploadResults.resultsMedia.map(mediaUrl => (
                      <div key={mediaUrl} className="col">
                        <img src={mediaUrl} style={{ maxWidth: 180 }} alt="results" />
                        <br />
                        <br />
                        <a href={mediaUrl} className="button print primary small" style={{ fontWeight: 600 }}>
                          Download GIF
                        </a>
                        <br />
                      </div>
                    ))}
                  </div>
                )
              }
            </section>
          )}
          { waiting && (
            <section className="process-section">
              <div className="loader">Loading...</div>
              <p>Digesting... results will appear here soon.</p>
            </section>
          )}
          { errorMsg && (
            <section className="process-section">
              <h3>Whoops!</h3>
              <figcaption className="bar-alert">{errorMsg}</figcaption>
            </section>
          )}
        </div>
      </article>
    </Layout>
  );
};

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <UploaderPage location={props.location} data={data} {...props} />
    )}
  />
);
